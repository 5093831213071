<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-22 17:20:56
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 15:27:12
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/components/EditSupplierModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="editSupplierVisible"
    :title="(isAdd ? '添加' : '编辑') + '供应商'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="548px"
  >
    <div class="form-container">
      <a-form-model ref="ruleForm" :model="query" v-bind="layout" labelAlign="left">
        <a-form-model-item
          label="供应商名称"
          :rules="[{ required: true, message: '请输入供应商名称', trigger: 'blur' }]"
          prop="name"
        >
          <a-input v-model.trim="query.name" placeholder="请输入供应商名称"></a-input>
        </a-form-model-item>
        <a-form-model-item
          :rules="[{ required: true, message: '请选择广告位状态', trigger: 'change' }]"
          prop="placeSts"
        >
          <m-tips
            slot="label"
            :content="`若需要对广告位精细运营管理，选择开启状态，可以创建广告位，并针对广告位配置预算。<br/>
若不需要在${title}进行广告位管理，选择关闭即可，节省运营成本。<br/>
预算配置生效优先级：广告位 > 供应商`"
            title="自定义广告位"
            :width="200"
          ></m-tips>
          <a-radio-group v-model="query.placeSts">
            <a-radio value="A">
              开启
            </a-radio>
            <a-radio value="S">
              关闭
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          :rules="[{ required: true, message: '请输入利润率', trigger: 'blur' }]"
          prop="profitRate"
        >
          <m-tips
            slot="label"
            :content="`针对该供应商获得的广告收入，平台预留设置利润率（%），为平台自身利润。<br/>
利润率生效优先级：供应商 < 供应商详情 < 广告位 < 广告位详情`"
            title="利润率"
            :width="200"
          ></m-tips>
          <a-input @input="changeProfitRate" v-model.trim="query.profitRate" placeholder="请输入利润率">
            <span class="percent-text" slot="suffix">%</span>
          </a-input>
        </a-form-model-item>
        <a-form-model-item
          prop="sts"
          label="选择状态"
        >
          <a-radio-group v-model="query.sts">
            <a-radio value="A">
              开启
            </a-radio>
            <a-radio value="S">
              关闭
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { addSupplier, updateSupplier } from '@/apiForManage/flow/supplier'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },
      query: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    supplierInfo: {
      default: () => ({}),
      type: Object
    }
  },
  computed: {
  ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title
    }),
    editSupplierVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          if (this.isAdd) {
            this.query = {
              name: undefined,
              placeSts: 'S',
              profitRate: undefined,
              sts: 'A'
            }
          } else {
            this.query = {
              ...this.supplierInfo
            }
          }
        }
      }
    }
  },
  methods: {
    changeProfitRate (e) {
      this.query.profitRate = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
      if (+this.query.profitRate > 100) {
        this.query.profitRate = 100
      }
    },
    handleCancel () {
      this.editSupplierVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            const { code } = await addSupplier(this.query)
            if (code === 200) {
              this.$message.success('添加成功')
              this.$emit('editSuccess')
            }
          } else {
            const { code } = await updateSupplier(this.query)
            if (code === 200) {
              this.$message.success('编辑成功')
              this.$emit('editSuccess')
            }
          }
          this.editSupplierVisible = false
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.form-container{
  padding: 0 36px;
}
</style>
