<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-06 11:19:43
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-24 10:09:35
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/Supplier/RequestTableItem/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="request-table-item">
    <div class="left">
      {{ numFormat(row.request || 0, 3, '') }}
    </div>
    <div class="right">
      <SimpleChart :dateList="dateList" :dataList="dataList" title="流量请求数"/>
    </div>
  </div>
</template>

<script>
import SimpleChart from '../SimpleChart'
import { numFormat } from '@/utils/dealNumber'
export default {
  name: 'RequestTableItem',
  components: { SimpleChart },
  props: {
    row: {
      default: () => ({
        request: 0,
        requestList: []
      }),
      type: Object
    }
  },
  computed: {
    dateList () {
      return (this.row.requestList || []).map((item) => item.date)
    },
    dataList () {
      return (this.row.requestList || []).map((item) => item.request)
    }
  },
  methods: {
    numFormat
  }
}
</script>

<style lang="less" scoped>
.request-table-item{
  width: 100%;
  position: relative;
  display: flex;
  .left{
    width: 55%;
    line-height: 40px;
  }
  .right{
    width: 0;
    flex: 1 auto;
    height: 40px;
  }
}
</style>
